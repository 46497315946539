import React from "react";
import {Link, useStaticQuery, graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Section from "./section";


function ServiceItem({title, id, Image, ...props}) {
    return (
        <Link to={`/services/${id}`} {...props}>
            <div className="w-40 lg:w-52 xl:w-80 h-40 lg:h-52 xl:h-80 drop-shadow-lg rounded-sm border-4 border-white hover:border-secondary m-1 relative" >
                {Image}
                <h4 className="absolute bottom-2 left-2 text-white text-lg xl:text-2xl text-shadow-md">{title}</h4>
            </div>
        </Link>

    )
}

export default function Services () {
    const {services} = useStaticQuery(graphql`
        query {
            services: allServicesJson {
                nodes {
                  key
                  title
                  thumbnail {
                    path {
                      childImageSharp {
                        gatsbyImageData(width:512)
                      }
                    }
                  }
                }
            }
        }
    `)

    return (
        <Section>
            <div className="container mx-auto">
                <div>
                    <div className="flex flex-wrap lg:flex-nowrap items-center">
                        <div className="mb-2 lg:mb-0">
                            <p className="uppercase leading-4 text-secondary pl-1">
                                Cost Effective
                            </p>
                            <h2 className="font-black font-display text-2xl md:text-4xl text-primary leading-tight whitespace-nowrap">Consulting Services</h2>
                        </div>
                        <p className="text-base md:text-lg font-thin lg:pl-12">
                            Ram Environmental Consulting offers a varity of cost-effective consulting services to help you ensure that you meet your regulatory requirements.
                        </p>
                    </div>
                    <hr className="border-2 text-secondary mt-3 mb-6"/>
                </div>
                <div className="flex flex-wrap justify-center items-center">
                    {services.nodes.map(s =>
                        <ServiceItem key={s.key} id={s.key} title={s.title} Image={<GatsbyImage alt={s.title_long} image={getImage(s.thumbnail.path)} className="brightness-50 hover:brightness-75"/> } />)}
                </div>
            </div>
        </Section>
    )
}
