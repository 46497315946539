import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

export default function Hero() {
    const {hero} = useStaticQuery(graphql`
        query {
          hero: file(relativePath: {eq:"hero.jpg"}) {
            publicURL
          }
        }
      `)
    console.log(hero.childImageSharp);
    return (
        <div className="w-full h-56 md:h-64 lg:h-96 bg-cover grayscale-[25%]" style={{
            backgroundImage: `url('${hero.publicURL}')`
        }}>
            {/*<GatsbyImage image={getImage(hero)} alt=""*/}
            {/*    className="grayscale-[25%]"*/}
            {/*    style={{*/}
            {/*        gridArea: "1/1"*/}
            {/*    }}/>*/}
            {/*<div className="grid relative place-items-center text-white border-t-2 border-b-2 border-[#efefef]"*/}
            {/*    style={{*/}
            {/*        gridArea: "1/1",*/}
            {/*    }}*/}
            {/*>*/}
                <div className="container mx-auto flex flex-col h-full items-center justify-center text-white py-6">
                    <div className="px-2">
                        <h2 className="text-2xl md:text-5xl xl:text-7xl font-black font-display text-shadow-sm">RAM Environmental Consulting</h2>
                        <p className="text-lg md:text-2xl xl:text-3xl text-shadow-sm font-bold leading-6 mt-2 sm:mt-4">
                            Site-specific environmental consulting services to help you understand, manage, and reduce your environmental impacts.
                        </p>
                    </div>
                </div>

            {/*</div>*/}
        </div>
    )
}
