import * as React from 'react'
import Hero from '../components/hero';
import Nav from '../components/nav';
import Page from "../components/page";
import Seo from "../components/seo";
import Services from "../components/services";
import About from "../components/about";

const IndexPage = () => {
  return (
      <Page>
          <Seo/>
          <Nav/>
          <Hero/>
          <Services/>
          <About/>
      </Page>
  )
}

export default IndexPage
