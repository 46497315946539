import React, {useState} from 'react'
import Section from './section'
import {StaticImage} from "gatsby-plugin-image";

export default function About() {
    const [showMore, setShowMore] = useState(false);
    return (
        <Section>
            <div className="flex flex-wrap items-center">
                <div className="w-full lg:w-1/2 lg:p-6 xl:p-12">
                    <StaticImage src="../images/andrea.jpg" alt="Andrea Ram, president of Ram Environmental Consulting"
                                 className="drop-shadow-lg rounded-sm "/>
                </div>
                <div className="w-full lg:w-1/2 pt-4 lg:pt-0">
                    <p className="uppercase leading-4 text-secondary pl-1">
                        President
                    </p>
                    <h2 className="font-black font-display text-4xl text-primary leading-tight whitespace-nowrap">Andrea Ram</h2>
                    <hr className="border-2 text-secondary mt-3 mb-6"/>
                    <div className="md:text-lg font-thin">
                        <p>
                            Andrea Ram is the president of Ram Environmental Consulting (REC) and has been specializing in environmental impact assessments, environmental planning, migratory bird surveys, wildlife and aquatic monitoring and impact mitigation, environmental construction monitoring, and mapping for over 13 years.
                            <button className={`ml-1 font-bold text-primary hover:text-secondary cursor-pointer transition-opacity duration-500 ${showMore?"opacity-0":"opacity-100"}`} onClick={() => setShowMore(true)}>Read More</button>.
                        </p>
                        <div className={`transition-opacity duration-500 ${showMore?"opacity-100" : "opacity-0 h-0"}`}>
                            <p className="mt-2">
                                REC’s use and extensive knowledge of Geographic Information Systems (GIS) allows us to provide you with real-time data that is easily interpreted and easily accessible. Our company has the capacity to handle all of your wildlife, aquatic and mapping needs. REC also has a close working relationship with a consulting consortium in the Bow Valley that has expertise in soils, reclamation and vegetation allowing us to offer you one point of contact for your environmental needs.
                            </p>
                            <p className="mt-2">
                                REC strives to find cost-effective solutions based on your needs while helping you minimize your environmental impacts. Our goal is to help you find environmentally responsible solutions for your project and to help you finish on time and on budget while meeting regulatory requirements.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}
